@use "../../config/variable.scss" as vars;

.userScreen-Box2 {
  border: 1px solid black;
  padding: 23px;
  border-radius: 30px;
}
.userScreen-Img {
  border-radius: 50%;
  object-fit: cover;
  width: 137px;
  height: 137px;
}
.userScreen-Txt1 {
  font-family: vars.$Medium;
  font-size: 15px;
}
.userScreen-txtColor {
  color: #9e9e9e;
}
.userScreen-Txt2 {
  font-family: vars.$Regular;
  font-size: 15px;
}
.profile-title {
  font-family: vars.$Medium;
  font-size: 10px;
  border-radius: 30px;
  border: 1.3px solid black;
  padding: 10px;
  width: 120px;
  text-align: center;
  cursor: pointer;
}
.userScreen-imgBox1 {
  width: 137px;
  height: 137px;
  background-color: #d8d8d852;
  border-radius: 50% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  position: relative;

  cursor: pointer;
}
.inputFile {
  width: 100%;
  height: 240px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.userScreen-imgTxt {
  font-family: vars.$Regular;
  font-size: 12px;
}
.userScreen-Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
