@use "../../config/variable.scss" as vars;

.dashboardHeader-Box1 {
  padding-top: 31px;
}
.dashboardHeader-Txt1 {
  font-family: vars.$Thin;
  font-size: 24px;
}
.dashBoard-Txt1 {
  font-family: vars.$Medium;
  font-size: 14px;
}
.dashBoard-Txt2 {
  font-family: vars.$Medium;
  font-size: 64px;
  // padding-bottom: 10px;
  padding-top: 10px;
}
.dashBoard-txt3 {
  font-family: vars.$Medium;
  font-size: 24px;
}
.dashBoard-Txt4 {
  font-family: vars.$Medium;
  font-size: 11px;
  color: white;
}
.dashBoard-Txt5 {
  font-family: vars.$Medium;
  font-size: 20px;
}
.dashBoard-Txt6 {
  font-family: vars.$Medium;
  font-size: 12px;
  color: white;
}
.order-Box2 {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: #356bf8;
  display: grid;
  place-items: center;
}
// .order-Box1 {
//   border-bottom: 1px solid black;
//   padding: 6px;
// }
.dashboardHeader-Box2 {
  width: 100%;
  height: 82vh;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  border-radius: 12px;
}
.dashboardHeader-Box2::-webkit-scrollbar {
  width: 0;
  background: transparent; /* Optional: Set the background to match your container */
}
.dashboardHeader-Box3 {
  width: 100%;
}
