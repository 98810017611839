@use "../../config//variable.scss" as vars;
.orderList-Box1 {
  width: 60px;
  height: 60px;
  background-color: #dedede !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.orderList-td {
  height: 60px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.right {
    align-items: flex-end;
  }
}
.orderList-Txt1 {
  font-family: vars.$SemiBold;
  font-size: 14px;
}
.orderList-main {
  border: 1px solid grey;
  border-radius: 12px;
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
}
.orderList-Txt2 {
  font-family: vars.$SemiBold;
  font-size: 16px;
}
.orderList-Txt3 {
  font-family: vars.$Medium;
  font-size: 12px;
  color: #bcbcbc;
}
.orderList-Txt4 {
  font-family: vars.$Medium;
  font-size: 12px;
}
.orderList-black {
  color: #000;
}
