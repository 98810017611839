@font-face {
  font-family: "LexendExa-Thin";
  src: url("./assets/fonts/LexendExa-Thin.ttf") format("opentype");
}
@font-face {
  font-family: "LexendExa-Regular";
  src: url("./assets/fonts/LexendExa-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "LexendExa-Medium";
  src: url("./assets/fonts/LexendExa-Medium.ttf") format("opentype");
}
@font-face {
  font-family: "LexendExa-SemiBold";
  src: url("./assets/fonts/LexendExa-SemiBold.ttf") format("opentype");
}
@font-face {
  font-family: "LexendExa-Bold";
  src: url("./assets/fonts/LexendExa-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "NotoKufiArabic_Regular";
  src: url("./assets/fonts/NotoKufiArabic-Regular.ttf") format("opentype");
}
/* body::-webkit-scrollbar {
  display: none;
  overflow-y: hidden;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

/* COAT_IMAGE == "https://bairuha-bucket.s3.ap-south-1.amazonaws.com/hadaq/coat.png
pants_Image= "https://bairuha-bucket.s3.ap-south-1.amazonaws.com/hadaq/pants.png
shirt_image == "https://bairuha-bucket.s3.ap-south-1.amazonaws.com/hadaq/shirt.png"
thawab_imag== "https://bairuha-bucket.s3.ap-south-1.amazonaws.com/hadaq/THAWA.png" */
