@use "../../config/variable.scss" as vars;
.adminRegister-Box1 {
  width: 100%;
  height: 100vh;
  background-size: cover;
  position: absolute;
  top: 0;
  background-image: url("../../assets/images/homeImage.jpg");
}
.adminRegister-Box2 {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 75px;
}
.adminRegister-Box3 {
  height: 80vh;
  display: flex;
  justify-content: end;
  align-items: center;
}

.adminRegister-Box4 {
  width: 560px;
  z-index: 10;
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 47px;
  @media screen and (max-width: 576px) {
    padding: 0px;
  }
}
.adminRegister-Box6 {
  display: flex;
  justify-content: space-between;
}
.adminRegister-Box7 {
  height: 80vh;
  display: flex;
  align-items: center;
}
.adminRegister-Txt1 {
  font-size: 94px;
  font-family: vars.$Regular;
  @media screen and (max-width: 576px) {
    font-size: 75px;
  }
}
.adminRegister-Txt2 {
  font-size: 63px;
  font-family: vars.$Regular;
  @media screen and (max-width: 576px) {
    font-size: 48px;
  }
}
.adminRegister-Txt3 {
  font-family: vars.$Bold;
  font-size: 32px;
  text-align: center;
  line-height: 40px;
}
.adminRegister-Txt4 {
  font-family: vars.$Regular;
  font-size: 15px;
  text-align: center;
}
.adminRegister-Txt5 {
  font-family: vars.$Regular;
  font-size: 14px;
  text-align: center;
}
.adminRegister-Txt6 {
  text-decoration: underline;
  color: blue;
}
.adminRegister-Txt7 {
  font-family: vars.$Bold;
  font-size: 32px;
  text-align: center;
  line-height: 40px;
  width: 375px;
  text-align: end;
}
.adminRegister-Btn1 {
  padding: 10px 50px 0px 50px;
}
