$Thin: "LexendExa-Thin";
$Regular: "LexendExa-Regular";
$Medium: "LexendExa-Medium";
$SemiBold: "LexendExa-SemiBold";
$Bold: "LexendExa-Bold";
$NotoKufiArabic_Regular: "NotoKufiArabic_Regula";

$primary_Color_DarkBlue: #002da2;
$primary_Color_Grey: #b6b6b6;
$primary_Color_Grey2: #d9d9d9;
$primary_Color_Light_Grey: #c1c1c1e5;
$Modal_BgColor: #c1c1c1e5;
