@use "../../config/variable.scss" as vars;
.header-Box1 {
  width: 100%;
  height: 120px;
  position: fixed;
  top: 0;
  z-index: 10;
}
.header-Box1 {
  width: 100%;
  height: 130px;
  background-size: cover;
  background-image: url(../../assets/images/SCREEN2.jpg);
  padding-top: 14px;
}
.header-Box2 {
  width: 100%;
  height: 100%;
  padding-top: 24px;
}
.header-DashBoard {
  width: 100%;
  height: 100px;
}
.header-Box1-transparent {
  width: 100%;
  height: 100px;
  position: relative;
  z-index: 1;
}
.header-Box2-transparent {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.header-Box3 {
  display: flex;
  align-items: center;
}
.header-txt1 {
  font-family: vars.$Regular;
  font-size: 20px;
  padding: 10px;
}
.header-Box4 {
  border: 1px solid black;
  height: 40px;
}
.header_Bttn1 {
  width: 100%;
  height: 39px;
  border-radius: 30px !important;
  color: vars.$primary_Color_DarkBlue !important;
  background-color: transparent !important;
  border: 2px solid #002da2;
}
.header_Bttn2 {
  width: 100%;
  height: 39px;
  border-radius: 30px !important;
  color: white !important;
  background-color: transparent !important;
  border: 2px solid #fff;
}
.header-txt2 {
  font-family: vars.$NotoKufiArabic_Regular;
  font-size: 50px;
  color: white;
}
.header-txt3 {
  font-family: vars.$Thin;
  font-size: 30px;
  color: white;
}
