@use "../../config/variable.scss" as vars;
.block1_Box1 {
  width: 100%;
  height: 100vh;
  background-size: cover;
  position: absolute;
  top: 0;
}
.block1_Box2 {
  height: 100vh;
  padding-left: 112px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 75px;
}
.block1_Box3 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding-right: 112px;
}
.block1_Box3 {
  height: 100vh;
}
////////////////////------LOGINMODAL-------///////////////////////
.LoginModal-Txt1 {
  font-family: vars.$SemiBold;
  font-size: 32px;
  color: #212b27;
}
.LoginModal_Box1 {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoginModal_Box2 {
  width: 100%;
  z-index: 10;
  background-color: vars.$Modal_BgColor;
  padding: 62px;
  border-radius: 47px;
}
.loginModal_Txt1 {
  font-family: vars.$Regular;
  font-size: 20px;
}
.loginScreen_Box3 {
  display: flex;
  justify-content: space-between;
}
.loginScreen_Box4 {
  padding-top: 49px;
}
.loginScreen_bttn1 {
  width: 100%;
  height: 60px;
  background-color: vars.$primary_Color_DarkBlue !important;
  border-radius: 30px !important;
  border: none !important;
  font-size: 20px;
  font-family: vars.$Regular !important;
  font-size: 20px !important;
  color: #fff !important;
}
.loginScreen_bttn2 {
  width: 100%;
  height: 60px;
  background-color: #d9d9d9 !important;
  border-radius: 30px !important;
  border: none !important;
  margin-top: 18px;
  font-family: vars.$Regular !important;
  font-size: 20px !important;
  color: #fff !important;
}
