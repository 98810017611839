@use "../config/variable.scss" as vars;

.payment-Box1 {
  width: 100%;
  // height: 313px;
  background-color: #bdbdbd33;
  border-radius: 30px;
}
.payment-Box2 {
  font-family: vars.$SemiBold;
  font-size: 12px;
  padding: 24px;
  text-align: center;
  background: #bdbdbd33;
  border-radius: 30px 30px 0px 0px;
}
.payment-Box3 {
  padding: 20px;
}
.payment_DebitBtn {
  background: linear-gradient(180deg, #c7c7e2 29.82%, #002da2 119.3%);
  border-radius: 30px !important;
  border: none !important;
  font-family: vars.$Regular !important;
  font-size: 10px !important;
  color: #fff !important;
  padding: 0px !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 57px !important;
}
.payment_CreditBtn {
  background: linear-gradient(
    180deg,
    #e3e3e3 29.82%,
    rgba(74, 74, 74, 0) 119.3%
  );
  border-radius: 30px !important;
  border: none !important;
  font-family: vars.$Regular !important;
  font-size: 10px !important;
  color: #000 !important;
  padding: 0px !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 57px !important;
}
.noDataFound-Box1 {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
