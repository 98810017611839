@use "../../config/variable.scss" as vars;

.category-table-card-image {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.ImagePicker-backgroud {
  border-radius: 6px;
  border: 2px dashed black;
  background-color: #8080800f;
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: vars.$Medium;
  font-size: 13px;
  text-align: center;
  color: grey;
  margin-bottom: 20px;
  padding: 25px;
  position: relative;
  &.small {
    height: 170px;
    padding: 20px 10px;
  }
}
.imagePickerImg {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  object-fit: cover;
}
