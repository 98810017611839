@use "./config/variable.scss" as vars;

.white-Card {
  width: 100%;
  background-color: white;
  border-radius: 30px;
  padding: 24px;
}
.blue-Card {
  width: 100%;
  height: 100%;
  background-color: #356bf8;
  border-radius: 30px;
  padding: 24px;
}
.head_Txt1 {
  font-family: vars.$NotoKufiArabic_Regular;
  font-size: 94px;
}
.head_Secondary_Txt1 {
  font-family: vars.$NotoKufiArabic_Regular;
  font-size: 94px;
  color: #fff;
}
.head_Txt2 {
  font-family: vars.$Regular;
  font-size: 63px;
}
.head_Secondary_Txt2 {
  font-family: vars.$Regular;
  font-size: 63px;
  color: #fff;
}

.primary_bttn1 {
  background-color: vars.$primary_Color_DarkBlue !important;
  border-radius: 30px !important;
  border: none !important;
  font-family: vars.$Regular !important;
  font-size: 20px !important;
  color: #fff !important;
  padding: 0px !important;
  height: 60px !important;
}
.primary_bttn2 {
  background-color: vars.$primary_Color_Grey2 !important;
  border-radius: 30px !important;
  border: none !important;
  font-family: vars.$Regular !important;
  font-size: 20px !important;
  color: #fff !important;
  padding: 0px !important;
  height: 60px !important;
}
.primary_bttn3 {
  background-color: vars.$primary_Color_DarkBlue !important;
  border-radius: 30px !important;
  border: none !important;
  font-family: vars.$Regular !important;
  font-size: 14px !important;
  color: #fff !important;
  padding: 0px !important;
}
.primary_bttn4 {
  background-color: vars.$primary_Color_Grey2 !important;
  border-radius: 30px !important;
  border: none !important;
  font-family: vars.$Regular !important;
  font-size: 14px !important;
  color: #fff !important;
  padding: 0px !important;
}
.input_radius {
  border-radius: 30px;
  height: 60px;
  padding-left: 28px;
}
.header_Bttn1 {
  width: 100%;
  height: 39px;
  border-radius: 30px !important;
  color: vars.$primary_Color_DarkBlue !important;
  background-color: transparent !important;
  border: 2px solid #002da2;
}
.header_Bttn2 {
  width: 100%;
  height: 39px;
  border-radius: 30px !important;
  color: white !important;
  background-color: transparent !important;
  border: 2px solid #fff;
}
.Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.screen-Box2 {
  padding: 0px 112px 0px 112px;
}
.screen-title {
  font-family: vars.$Medium;
  font-size: 10px;
  border-radius: 30px;
  border: 1.3px solid black;
  padding: 10px;
  min-width: 80px;
  text-align: center;
}
.screen-txt1 {
  font-family: vars.$Regular;
  font-size: 20px;
  padding: 10px;
  color: #c2c2c2;
}

// .screen-Box1 {
//   width: 100%;
//   height: 100%;
//   background-size: cover;
//   background-image: url(./assets/images/SCREEN2.jpg);
// }
.profileCard-Img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  object-fit: cover;
}
.ProfileCard-Box1 {
  width: 100%;
  height: 122px;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
}
.screen-text1 {
  font-family: vars.$Regular;
  font-size: 10px;
  @media screen and (max-width: 992px) {
    font-size: 20px;
    padding: 10px;
  }
}
.screen-Txt2 {
  font-family: vars.$Medium;
  font-size: 14px;
  white-space: nowrap;
}
.screen-Txt4 {
  font-family: vars.$Regular;
  font-size: 10px;
  padding-top: 14px;
}

.screen-Txt3 {
  color: #9e9e9e;
}
.Product-Img {
  object-fit: cover;
  cursor: pointer;
  width: 100%;
  height: 100%;
  // border: 2px solid #dedcdc;
}

.ProductTypeScreen-Box1 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  // &:hover {
  //   border: 5px solid #002da2;
  // }
}
.ProductTypeScreenSelected-Box1 {
  border: 5px solid rgb(14, 14, 22);
  width: 500px;
  height: 500px;
  border-radius: 50%;
}

.screen-vectorCard {
  width: 100%;
  height: 64px;
  border-radius: 20px;
  border: 1px solid black;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.screen-vectorCard2 {
  width: 100%;
  height: 64px;
  border-radius: 20px;
  border: 2px solid #002da2;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.MeasurmentScreen-Img {
  object-fit: cover;
}

.measurementScreen-input {
  border-radius: 30px;
  margin-top: 12px;
  font-size: 12px !important;
}
// hr {
//   border: 1px solid black !important;
// }
.invioce-Txt1 {
  font-family: vars.$SemiBold;
  font-size: 32px;
  color: vars.$primary_Color_DarkBlue;
}
.formItem {
  height: 40px !important;
}
.formLabel {
  font-family: vars.$Regular;
  font-size: 10px;
  padding-bottom: 5px;
  padding-left: 6px;
}
.table-Action {
  display: flex;
  justify-content: space-evenly;
}
.table-Title {
  font-family: vars.$Medium;
  font-size: 10px;
}
.table-Txt {
  font-family: vars.$Regular;
  font-size: 10px;
}
.table-Head {
  font-family: vars.$SemiBold;
  font-size: 24px;
  padding: 20px;
}
.error_Txt {
  font-family: vars.$SemiBold;
  font-size: 14px;
  color: #ff1f1f;
}
.overFlow-Box1 {
  width: 100%;
  height: 80vh;
}
.overFlow-Box2 {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  border-radius: 12px;
}
.overFlow-Box2::-webkit-scrollbar {
  width: 0;
  background: transparent; /* Optional: Set the background to match your container */
}
.dashBoard-Box1 {
  padding-left: 80px;
  padding-right: 80px;
  height: 100vh;
}
.dataTable-Box1 {
  padding-left: 34px;
  padding-right: 34px;
  height: 100vh;
}
.ImageUpload-Box2 {
  width: 100%;
  height: 200px;
  border: dashed 2px #75757538;
  border-radius: 8px;
  background-color: #efefef4a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ImageUpload-Box1 {
  padding-top: 20px;
}

.imageUpload-Txt1 {
  font-family: vars.$Medium;
  font-size: 12px;
  padding: 10px;
}
.imageUploader-Remove {
  position: absolute;
  bottom: -10px;
  right: -10px;
}
.imageUpload-Img {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
}
.custom-select .ant-select-selector {
  border-radius: 30px;
}
