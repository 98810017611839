@use "../../config//variable.scss" as vars;

.updateProfile-Box1 {
  // width: 258px;
  // height: 258px;
  background-color: #d8d8d852;
  border-radius: 50%;
  @media screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
    border-radius: 24px;
  }
}
.updateProfile-Box2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.updateProfile-Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  @media screen and (max-width: 992px) {
    border-radius: 24px;
  }
}
.profile-edit {
  position: absolute;
  bottom: -16px;
  background-color: #356bf8;
  border-radius: 100px;
  width: 65px;
  cursor: pointer;
  @media screen and (max-width: 992px) {
    position: absolute;
    bottom: 40 !important;
  }
}
.updateProfile-Txt1 {
  font-family: vars.$SemiBold;
  font-size: 20px;
}
// .profilUpdate-Box1 {
//   width: 200px;
//   height: 200px;
//   background-color: #d8d8d852;
//   border-radius: 50% !important;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   border-radius: 6px;
//   position: relative;
//   z-index: 10;
//   cursor: pointer;
// }
// .profilUpdate-Img {
// width: 100%;
// height: 100%;
// object-fit: cover;
// border-radius: 50%;
// }
// // .profilUpdate-box2 {
// //   position: absolute;
// //   bottom: 0;
// //   left: 20px;
// // }
// // .profileUpdate-imgBox1 {
// //   width: 100%;
// //   height: 270px;
// //   border-radius: 50%;
// //   background-color: #d8d8d852;
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// //   justify-content: center;
// //   border-radius: 6px;
// //   position: relative;
// //   z-index: 10;
// //   cursor: pointer;
// // }
// // .profilUpdate-inputFile {
// //   width: 100%;
// //   height: 200px;
// //   position: absolute;
// //   opacity: 0;
// //   border-radius: 50% !important;
// //   cursor: pointer;
// // }
// // .profileUpdate-imgTxt {
// //   font-family: vars.$Regular;
// //   font-size: 12px;
// //   position: absolute;
// //   bottom: 0;
// // }
// ///////////////////-------PROFILE-IMAGE--------------////////////////
// .profile-picture-container {
//   height: 100%;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
// }
// .profile-picture {
//   position: relative;
//   width: 100%;
//   display: flex;
//   justify-content: center;
// }

// .profile-img {
//   width: 80%;
//   border-radius: 100%;
//   cursor: pointer;
// }

// .profile-text-box {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

// .profile-txt1 {
//   font-family: vars.$Medium;
//   font-size: 20px;
//   display: flex;
//   flex-direction: row;
// }

// .profile-txt2 {
//   font-family: vars.$Medium;
// }
