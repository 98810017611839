@use "../config/variable.scss" as vars;
.Navigation-container {
  min-height: 100vh;
  background-color: #fff;
}
.navigation-Sider {
  width: 329px !important;
  height: 100vh !important;
  background-color: #002da2 !important;
  border-radius: 0px 51px 51px 0px !important;
}
.sideBar-Txt1 {
  font-family: vars.$Medium;
  font-size: 24px;
  color: white;
}
.sideBar-Txt2 {
  font-family: vars.$Thin;
  font-size: 16px;
  color: white;
  // line-height: 45px;
  margin-top: 24px;
  cursor: pointer;
  white-space: nowrap;
}
.sideBar-Box1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 40px;
}
